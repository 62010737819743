.create-pool-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  margin: 16px;
}

.add-match-button-container {
  display: flex;
  flex: 2;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.matchs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 5;
  overflow-y: auto;
  width: 100%;
}

.send-pool-button-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.preview-match-swipe {
  .js-delete {
    background: transparent !important;
  }
}

.js-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-match-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 95%;
    border: solid 1px #b8b8b8;
    border-radius: 8px;

    .vs-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
    .team-container {
      display: flex;
      flex: 4;
      justify-content: center;
      align-items: center;
    }

    .text {
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.set-results-container {
  display: flex;
  flex-grow: 1;
  margin: 16px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
}

.id-pool-container {
  display: flex;
  flex: 1;
  height: 50px;
  margin-bottom: 64px;

  span {
    color: aliceblue;
    font-size: 18px;
    font-weight: bold;
  }
}

@function get-vw($target) {
  $vw-context: (1000 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
