.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 16px;
}

.row-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;
}

.funtionality {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 70px;
  height: 70px;

  border: 1px solid white;
  background-color: rgba($color: white, $alpha: 0.8);
  border-radius: 8px;

  .img-container {
    position: relative;
    .img {
      width: 32px;
      height: auto;
    }
  }

  .label-container {
    color: black;
    font-size: 12px;
    font-weight: 600;
  }

  .label-container {
    display: flex;
  }
}

.button {
  margin-top: 24px;
}

span {
  font-size: 32px;
  color: rgba($color: white, $alpha: 0.8);
  font-weight: 600;
}
