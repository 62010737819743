.pool-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  margin: 0 16px;
}

.matches-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
}

.match {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;

  .pool-results-container {
    margin-top: 0;
    width: 95%;
  }

  &.loser {
    .pool-results-container {
      border: solid 2px rgb(195, 0, 2);
    }
  }

  &.winner {
    .pool-results-container {
      border: solid 2px rgb(0, 180, 0);
    }
  }
}

.title {
  height: auto;
  color: aliceblue;
  font-size: 24px;
  font-weight: 600;
  margin-top: 38px;
}
