.results-container {
  height: 100%;
  width: 100%;
  margin: 16px;
}

.results {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  width: 99%;
  max-height: 80vh;
  border-top: solid 1px white;
  overflow-y: scroll;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.user-result-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: solid 1px white;
  border-top: solid 0px white;
  padding: 6px 0px;

  span {
    color: aliceblue;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin: 0 16px;

    &.no-pool {
      color: rgba($color: #ffffff, $alpha: 0.3);
    }
  }
}
