.pool-results-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 85%;
  height: 35px;
  min-height: 35px;
  margin-top: 12px;
  padding: 8px 0;
  border: solid 1px #b8b8b8;
  border-radius: 8px;
}

.text {
  color: white;
  font-size: 18px;
  font-weight: 600;
  width: 120px;
  text-align: center;
}
