.view-pool-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pool-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  margin: 32px 16px;
  width: 90%;
}

.send-button-container {
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
  margin: 0 0 16px 0;
}

.empty-pool-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  img {
    width: 156px;
    height: 156px;
  }
}
